<template>
  <VDialog
    v-model="show"
    v-bind="{...$attrs,...$props}"
    v-on="$listeners"
  >
    <template v-if="transparent">
      <slot name="default" />
    </template>
    <template v-else>
      <VCard
        flat
        class="rounded-lg pa-4"
      >
        <slot name="default" />
      </VCard>
    </template>

    <template #activator="data">
      <slot
        name="activator"
        v-bind="data"
      />
    </template>
  </VDialog>
</template>

<script>
export default {
  name: 'DialogWrapper',
  props: {
    value: {
      type: Boolean,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped>

</style>
