<template>
  <TTChip
    v-if="duration && !isNaN(duration)"
    color="tt-light-mono-4"
    :outlined="false"
    small
    data-test-label="chip-duration"
  >
    <VIcon
      size="14"
      color="tt-light-mono-24"
      data-test="chip-duration-icon"
      left
    >
      fa fa-clock
    </VIcon>
    <span class="tt-text-caption tt-light-mono-64--text">
      <span
        v-if="hour"
        data-test="chip-duration-hour"
      >{{ $t('time.hours', {hour : hour}) }} </span>
      <span
        v-if="minute"
        data-test="chip-duration-minute"
      >{{ $t('time.minutes', {minute : minute}) }}</span>
    </span>
  </TTChip>
</template>

<script>
export default {
  name: 'ChipDuration',
  props: {
    duration: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    minute() {
      return this.duration % 60;
    },
    hour() {
      return Math.floor(this.duration / 60);
    },
  },
};
</script>
