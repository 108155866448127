<template>
  <TTChip
    v-if="max && !isNaN(max) && !isNaN(current)"
    color="tt-light-mono-4"
    :outlined="false"
    data-test-label="chip-bonus"
    small
  >
    <VIcon
      size="14"
      :color="current ? 'tt-light-yellow':'tt-light-mono-24'"
      left
      data-test="chip-bonus-icon"
    >
      fa fa-star
    </VIcon>
    <span
      class="tt-text-caption tt-light-mono-64--text"
      data-test="chip-bonus-text"
    >{{ current }}/{{ max }}</span>
  </TTChip>
</template>

<script>
export default {
  name: 'ChipBonus',
  props: {
    max: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
};
</script>
