<template>
  <VContainer
    class="pa-0"
    data-test="track-rating"
  >
    <VRow align="start">
      <VCol>
        <VRow
          dense
          data-test="track-rating-title"
        >
          <VCol cols="12">
            <span
              class="tt-text-caption"
            >
              {{ levelName }}
            </span>
          </VCol>
          <VCol cols="12">
            <h2 class="tt-text-title-2">
              {{ $t('common.rating') }}
            </h2>
          </VCol>
        </VRow>
      </VCol>
      <VCol cols="auto">
        <TTBtn
          icon
          width="auto"
          data-test-label="track-rating-button-close"
          @click="handleClose"
        >
          <VIcon
            color="tt-black"
          >
            fal fa-times
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>
    <VRow dense>
      <template v-for="(item, i) in transformedItems">
        <VCol
          :key="item.staff.firstName + i"
          cols="12"
        >
          <CardWrapper
            :shadow="item.staff.id === meta.id? 'always':'hover'"
            class="px-5"
            data-test="track-rating-user-card"
            :to="{name : Names.R_APP_PROFILE, params : {userId : String(item.staff.id)}}"
          >
            <VContainer>
              <VRow
                align="center"
                dense
              >
                <VCol cols="1">
                  <div
                    data-test="track-rating-user-card-position"
                    class="tt-text-title-2"
                  >
                    {{ i + 1 }}
                  </div>
                </VCol>
                <VCol cols="auto">
                  <TTAvatar
                    :text="getAvatarInitials(item)"
                    :src="item.staff.photoUrl"
                    data-test-label="track-rating-user-card-avatar"
                  />
                </VCol>
                <VCol cols="auto">
                  <div
                    data-test="track-rating-user-card-name"
                    class="tt-text-body-1"
                  >
                    {{ item.staff.firstName }} {{ item.staff.lastName }}
                  </div>
                </VCol>
                <VSpacer />
                <VCol cols="auto">
                  <div
                    data-test="track-rating-user-card-points"
                    class="tt-text-body-1 tt-light-mono-64--text"
                  >
                    {{ item.bonus }}
                  </div>
                </VCol>
              </VRow>
            </VContainer>
          </CardWrapper>
        </VCol>
        <VCol :key="`divider-${i}`">
          <VDivider v-if="i+1!==transformedItems.length" />
        </VCol>
      </template>
    </VRow>
  </VContainer>
</template>

<script>
import CardWrapper from '@/components/cards/CardWrapper.vue';

export default {
  name: 'TrackRating',
  components: { CardWrapper },
  inject: ['Names'],
  props: {
    meta: {
      type: Object,
      default: () => {
      },
    },
    level: {
      type: String,
      default: null,
    },
  },
  computed: {
    levelName() {
      if (this.level) {
        return `${this.$tc('names.levels', 0)} «${this.level}»`;
      }
      return '';
    },
    transformedItems() {
      return this.meta.ratings?.reduce((acc, item) => {
        if (acc.length < 10) {
          item.staff.forEach((staff) => {
            if (acc.length < 10) {
              acc.push({
                ...item,
                staff,
              });
            }
          });
        }
        return acc;
      }, []);
    },
  },
  methods: {
    getAvatarInitials(item) {
      return !item.staff.photoUrl ? (item.staff.firstName[0] + item.staff.lastName[0]) : '';
    },
    handleClose() {
      this.$emit('click:close');
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-item {
  height: 71px;
  text-decoration: none;
  margin-top: -1px;
}

.my-rating-item {
  background: map-get($tt-light-mono-0, 'base') !important;
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08);
  border-radius: 8px;
}

.rating-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
