<template>
  <VContainer class="pa-0">
    <VRow align="center">
      <VCol>
        <h1
          data-test="tracks-view-title"
          class="tt-text-headline-1"
        >
          {{ trackName }}
        </h1>
      </VCol>
      <VCol cols="auto">
        <VBtn
          color="orange"
          @click="addTracks"
        >
          Добавить уровней
        </VBtn>
      </VCol>
    </VRow>
    <div class="my-6">
      <template v-if="!tracks.length">
        <VRow>
          <VCol>
            <EmptySection
              :show-arrow="false"
              :title="$t('tracks.has_no_levels')"
              :text="$t('contact_manager_or_support')"
            />
          </VCol>
        </VRow>
      </template>
      <VRow
        v-for="(level, index) in tracks"
        :key="level.id"
        class="my-0"
      >
        <VCol
          :offset="index % 2 !== 0 ? (12 - $options.LEVEL_COLS) : 0"
          :cols="$options.LEVEL_COLS"
          class="py-0"
        >
          <TrackLine
            v-if="(index % 2 === 0) && index"
            :current-state="level.state"
            :prev-state="prevOnboardingTrack(index).state"
            :state-text="getStateText(level)"
            before
            data-test="levels-track-line-right"
          />
          <!-- TODO: роутинг по клику и проверка открыт или нет -->
          <TrackCard
            :data-test="`levels-track-card-${index}`"
            :level="level"
          />
          <TrackLine
            v-if="(index % 2 === 0) && index!== onboardingTrack.length - 1"
            :current-state="level.state"
            :next-state="nextOnboardingTrack(index).state"
            :state-text="getStateText(nextOnboardingTrack(index))"
            data-test="levels-track-line-left"
          />
        </VCol>
      </VRow>
    </div>
    <DialogWrapper
      v-model="showRatingModal"
      width="676"
      data-test="track-rating-dialog"
      @click:outside="closeRatingModal"
    >
      <TrackRating
        :level="trackName"
        :meta="staff"
        @click:close="closeRatingModal"
      />
    </DialogWrapper>
  </VContainer>
</template>

<script>
import TrackLine from '@/components/track/TrackLine.vue';
import TrackCard from '@/components/track/TrackCard.vue';
import { LEVEL_COLS } from '@/components/track/constans';
import { mapGetters } from 'vuex';
import EmptySection from '@/components/shared/EmptySection.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import TrackRating from '@/components/track/TrackRating.vue';

export default {
  name: 'TrackView',
  components: {
    TrackRating,
    DialogWrapper,
    EmptySection,
    TrackCard,
    TrackLine,
  },
  LEVEL_COLS,
  data() {
    return {
      trackName: 'Название трека',
      tracks: [],
      showRatingModal: false,
    };
  },
  computed: {
    ...mapGetters('levels', ['onboardingTrack']),
    ...mapGetters('user', ['staff']),
  },
  mounted() {
    this.$root.$on('click:show-rating', this.showRating);
  },
  beforeDestroy() {
    this.$root.$off('click:show-rating', this.showRating);
  },
  methods: {
    addTracks() {
      this.tracks = [...this.onboardingTrack];
    },
    showRating() {
      this.showRatingModal = true;
    },
    closeRatingModal() {
      this.showRatingModal = false;
    },
    nextOnboardingTrack(i) {
      return this.onboardingTrack[i + 1];
    },
    prevOnboardingTrack(i) {
      return this.onboardingTrack[i - 1];
    },
    getStateText(onboardingTrack) {
      return onboardingTrack?.details?.stateText;
    },
  },
};
</script>
